$(function(){

    $('.datepicker').datepicker({
        dateFormat: "yy-mm-dd"
    });

    /**
     * Add csrf token from meta tag to ajax requests
     */
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });

    /**
     * Disable disabled links
     */
    $('a.btn').each(function(i, el){
        console.log(el, i);
        if ($(this).prop('disabled')) {
            $(this).removeAttr('href');
        }
    });

    /**
     * Input masks
     */
    $('.format-mobile').inputmask({"mask": "999-999 99 99"});
    $('.format-registration').inputmask({"mask": "999999-9999"});
    $('.format-zipcode').inputmask({"mask": "999 99"});

    /**
     * Get next customer number
     */
    $('#update-customer-number-btn').on('click', function(e){
        e.preventDefault();

        var input = $(this).next('input');
        input.attr('disabled', 'disabled');

        $.post('/customers/get-number', function(response){
            $('#customer-number').val(response.number);
            input.removeAttr('disabled');
        });
    });

    /**
     * Make tr clickable
     */
    $('tr[data-href] > .clickable').on('click', function(){
        document.location = $(this).parent().data('href');
    });

    /**
     * Delete modal
     */
    $('#delete-modal').on('show.bs.modal', function (e) {
        var modal = $(this),
            button = $(e.relatedTarget),
            deleteBtn = modal.find('.delete-btn'),
            message = button.data('message');

        showModalContent('#delete-modal', message);

        // Reset data fields
        deleteBtn.removeData('id');
        deleteBtn.removeData('url');

        // Get data attributes
        deleteBtn.attr('data-id', button.data('id'));
        deleteBtn.attr('data-url', button.data('url'));
    });

    /**
     * Delete button in modal
     */
    $('#delete-modal .delete-btn').on('click', function (e) {
        //$(this).attr('disabled', 'disabled');
        var id = $(this).data('id'),
            token = $(this).data('token'),
            url = $(this).data('url'),
            method = 'DELETE';

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: {_method: method, _token: token},
            statusCode: {
                404: function(response) {
                    showModalContent('#delete-modal', response.responseJSON.message);
                }
            },
            success: function (response) {
                $('#delete-modal').modal('hide');
                showFlashMessage(response.message);
                $('.row-' + id).remove();
            }
        });
    });

    /**
     * Reset all modals on close
     */
    $('.modal').on('hidden.bs.modal', function() {
        $('.modal-body', this).html('');
    });

    //$('select.searchable').select2();

    $('.custom-checkboxes .municipality:checkbox, .custom-checkboxes .category:checkbox').on('click', function(){
        if ($(this).is(':checked')) {
            $(this).parent().addClass('checked');
            $(this).prop('checked ', false);
        } else {
            $(this).parent().removeClass('checked');
            $(this).prop('checked ', true);
        }
    });

    $('.toggle-checkboxes').on('click', function(){
        var el = $(this);

        checkboxes = $(el).parent().next().find(':checkbox').not($(this));

        if (el.is(':checked')) {
            for(var i = 0, n = checkboxes.length; i < n; i++) {
                $('#' + checkboxes[i].id).parent().addClass('checked');
                $('#' + checkboxes[i].id).prop('checked', true);
            }
        } else {
            for(var i = 0, n = checkboxes.length; i < n; i++) {
                $('#' + checkboxes[i].id).parent().removeClass('checked');
                $('#' + checkboxes[i].id).prop('checked', false);
            }
        }
    });

    $('.toggle-all-checkboxes').on('click', function(){
        var el = $(this);

        checkboxes = $(el).closest('form').find(':checkbox').not($(this));

        if (el.is(':checked')) {
            for(var i = 0, n = checkboxes.length; i < n; i++) {
                $('#' + checkboxes[i].id).parent().addClass('checked');
                $('#' + checkboxes[i].id).prop('checked', true);
            }
        } else {
            for(var i = 0, n = checkboxes.length; i < n; i++) {
                $('#' + checkboxes[i].id).parent().removeClass('checked');
                $('#' + checkboxes[i].id).prop('checked', false);
            }
        }
    });

    $('#contract_template_id').on('change', function(){

        var template = $(this).val();

        if(template == '') {
            $('.contract-form #subscription_period').val('');
            $('.contract-form #invoice_interval').val('');
            $('.contract-form #notice_period').val('');
            $('.contract-form #price').val('');
            $('.contract-form #discount').val('');
            $('.contract-form .fr-view').val();
            return;
        }

        $.get('/contract-templates/' + template, function(response){
            $('.contract-form #subscription_period').val(response.subscription_period);
            $('.contract-form #invoice_interval').val(response.invoice_interval);
            $('.contract-form #notice_period').val(response.notice_period);
            $('.contract-form #price').val(response.price);
            $('.contract-form #discount').val('0');
            $('.contract-form .fr-view').html(response.template);
        });
    });

    //$('input.typeahead').attr('autocomplete', 'off');

    var customers = new Bloodhound({
        datumTokenizer: function(datum) {
            return Bloodhound.tokenizers.whitespace(datum.value);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            wildcard: '%QUERY',
            url: '/ajax/get-customers/%QUERY',
            transform: function(response) {
                // Map the remote source JSON array to a JavaScript object array
                return $.map(response.data, function(data) {
                    return {
                        id: data.id,
                        number: data.number,
                        name: data.name,
                        registration: data.registration,
                        city: data.invoice_city,
                        ourRef: data.our_ref,
                        yourRef: data.your_ref
                    };
                });
            }
        }
    });

// Instantiate the Typeahead UI
    $('input.typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 1
    }, {
        display: 'name',
        limit: 10,
        source: customers,
        templates: {
            header: '<div class="tt-header"><span class="col-1">Kundnr</span><span class="col-2">Namn</span><span class="col-3">Ort</span><span class="col-4">Orgnr</span></div>',
            suggestion: function (data) {
                //console.log(data);
                return '<div><span class="col-1">' + data.number + '</span><span class="col-2">' + data.name + '</span><span class="col-3">' + data.city + '</span><span class="col-4">' + data.registration + '</span></div>';
            }
            //footer: '</table>'
        }
    }).on('typeahead:select', function(ev, suggestion) {
        $('#customer_id').val(suggestion.id);
        $('#our_ref').val(suggestion.ourRef);
        $('#your_ref').val(suggestion.yourRef);
        $('input.typeahead').typeahead('close');
    });

    $('input#contract_date').on('change', function(){
        var periodStart = moment($(this).val()).format('Y-MM-DD');
        var periodEnd = moment($(this).val()).add(1, 'years').format('Y-MM-DD');
        $('input#period_start').val(periodStart);
        $('input#period_end').val(periodEnd);
    });

    $('input#period_start').on('change', function(){
        var periodEnd = moment($(this).val()).add(1, 'years').format('Y-MM-DD');
        $('input#period_end').val(periodEnd);
    });
});

/**
 * Show flash message
 * @param message
 */
function showFlashMessage(message) {
    var flashNotice = $('#flash-notice');
    flashNotice.find('.body').html(message);
    flashNotice.fadeIn().effect("bounce", { times:3 }, 500);
    setTimeout(function(){ flashNotice.fadeOut() }, 10000);
}

/**
 * Show modal content
 * @param modal
 * @param content
 */
function showModalContent(modal, content) {
    $(modal).find('.modal-body').html(content);
}

function toggleCheckboxes(el) {
    checkboxes = $(el).closest('.custom-checkboxes > .inner').find(':checkbox').not($(this));
    for(var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].parent().addClass('checked');
        checkboxes[i].checked = el.checked;
    }
}
